<template>
    <div>
        <div class="jifen-change">
                <div class="jifen-top-title">
                  <span>积分换好礼</span>
                  <div class="fenye">
                    <div>{{ integralpageIndex }}/{{ integraltotal }}</div>
                    <div
                      :class="[integralpageIndex > 1 ? 'canChoose' : '']"
                      @click="changeintegral(1)"
                    >
                      <i class="el-icon-arrow-left"></i>
                    </div>
                    <div
                      :class="[
                        integralpageIndex < integraltotal ? 'canChoose' : ''
                      ]"
                      @click="changeintegral(2)"
                    >
                      <i class="el-icon-arrow-right"></i>
                    </div>
                  </div>
                </div>
                <div class="jifen-product-box">
                  <div
                    class="product-item"
                    v-for="item in integralgoodsList"
                    :key="item.Id"
                    @click="gotoGoodsdetai(item)"
                  >
                    <img
                      :src="item.CoverImg"
                      alt=""
                      style="width:140px;height:140px"
                    />
                    <p
                      class="textOver"
                      style="line-height: 20px;font-size: 12px;font-weight:700;margin-top:2px"
                    >
                      {{ item.GoodsName }}
                    </p>
                    <div class="hot_conntent_div_money">
                      <span>{{ item.DefaultPoints }}</span
                      ><span>积分</span><span>＋</span><span>￥</span
                      ><span>{{ item.DefaultPrice }}</span
                      ><span>￥{{ item.DefaultOriginalPrice }}</span>
                    </div>
                  </div>
                </div>
              </div>
    </div>
</template>
<script>
export default {
    data(){
        return{

        }
    }
}
</script>
<style lang='scss' scoped>
   .jifen-change {
    .jifen-top-title {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 60px;
      background: #f5f5f5;
      align-items: center;
      span {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 19px;
        color: #333333;
        opacity: 1;
        display: block;
        background: #f5f5f5;
      }
    }
    .clearance {
      width: 100%;
      height: 20px;
      display: block;
      background: #f5f5f5;
    }
    .jifen-product-box {
      display: flex;
      flex-wrap: wrap;
      padding: 20px 30px;
      box-sizing: border-box;
      .product-item {
        cursor: pointer;
        width: 16%;
        display: flex;
        flex-direction: column;
        margin-right: 44px;
        img {
          width: 100%;
        }
        p:first-child {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          line-height: 20px;
          color: #333333;
          opacity: 1;
          margin: 15px 0 10px 0;
        }
        p:last-child {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: bold;
          line-height: 20px !important;
          color: #f40707;
          opacity: 1;
        }
      }
      .product-item:nth-child(5n) {
        margin-right: 0;
      }
    }
  }
</style>