<template>
  <div>
    <div>
      <Topbar></Topbar>
      <div class="shopcar-content">
        <div class="shopcar-title">我的购物车</div>
        <div class="all-title">
          <div class="all-select">
            <van-checkbox v-model="checked" @click="checkAll"
              >全选</van-checkbox
            >
          </div>
          <div style="margin-left: 126px">商品信息</div>
          <div style="margin-left: 302px">单价</div>
          <div style="margin-left: 101px">数量</div>
          <div style="margin-left: 112px">金额</div>
          <div style="margin-left: 137px">操作</div>
        </div>

        <!-- 商品 -->
        <van-checkbox-group v-model="result" ref="checkboxGroup">
          <Nocontent v-if="goodsList.length==0" name='购物车空空如也'></Nocontent>
          <van-checkbox
            :name="i"
            class="order_info"
            v-for="(item, i) in goodsList"
            :key="i"
            @click="changeCheckbox(item)"
          >
            <div class="info_detail">
              <img :src="item.CoverImg" alt="" />
              <div class="detail_content"  @click.stop="todetail(item.GoodsId)">
                <div>
                  {{
                    item.GoodsName.length > 25
                      ? item.GoodsName.substr(0, 25) + "..."
                      : item.GoodsName
                  }}
                </div>
                <div>
                  <span>{{
                    item.SpecName
                  }}</span>
                </div>
                <div></div>
              </div>
              <!-- 单价 -->
              <div class="info_detail-tight">
                <div class="unitprice">
                  <span v-if="item.Points > 0" style="font-size: 12px"
                    >{{ item.Points }}积分 +</span
                  >
                  ￥<span>{{ item.Price }}</span>
                </div>

                <div class="info_num2" @click.stop="onChange(item)">
                  <!-- 数量 -->
                  <van-stepper v-model="item.GoodsCount"/>
                </div>
                <!-- 合计 -->
                <div class="addprice">
                  <span v-if="item.Points > 0" style="font-size: 12px"
                    >{{ item.Points * item.GoodsCount }}积分 +</span
                  >
                  ￥<span>{{ (item.Price * item.GoodsCount).toFixed(2) }}</span>
                </div>
                <!-- 删除 -->
                <div class="del" @mousemove="moudel(item,i)" @mouseleave="moudel2()" @click.stop="delitem(item,i)" :style="{ color: isdel==i ? '#f95e2e' : '#333333' }">删除</div>
              </div>
            </div>
          </van-checkbox>
        </van-checkbox-group>
        <!-- 结算 -->
        <van-submit-bar
          :price="total"
          button-text="结算"
          :suffix-label="totalPoint > 0 ? '+' + totalPoint + '积分' : ''"
          @submit="onSubmit"
        >
          <!-- <van-checkbox v-model="checked" @click="checkAll">全选</van-checkbox> -->
          <div class="delselect" @click="handleFather">删除选中</div>
        </van-submit-bar>
      </div>
    </div>
  </div>
</template>
<script>
import Topbar from "../PCshop/components/topbar.vue";
import { queryShopCartList, shopCartEdit, shopCartDelete, } from "@/api/shop";
import { Toast, Dialog } from "vant";
import Nocontent from '../shop/components/nocontent.vue';
import Bottomlist from './components/bottomlist.vue';
export default {
  components: {
    Topbar,Nocontent,Bottomlist
  },
  data() {
    return {
      checked2: false,
      result: [],
      checked: false,
      goodsList: [],
      isLoad: false,
      isEnd: false,
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      totalPoint: 0,
      carnum:0,
      isdel:999
    };
  },
  created() {
    if (localStorage.getItem("userInfo") != null) {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      this.getQueryShopCartList();
    } else {
      this.userInfo.Id = 0;
    }
    this.getQueryShopCartList();
  },
  methods: {
    //去商品详情页
    todetail(id){
      console.log(id,'rnm')
       this.$router.push({ name: 'PCshop/goods/goodsdetail', query: {id: id}});
    },
    //加减购物车
    onChange(obj){
      console.log(obj);
      if (obj.GoodsCount > 0){
        this.postEditShopCart(obj);
      }
      this.total = 0;
      this.totalPoint = 0;
      for (var i = 0; i < this.result.length; i++) {
        let obj = this.goodsList[this.result[i]];
        this.total +=
          (obj.Price == null ? 0 : obj.Price) * obj.GoodsCount * 100;
        this.totalPoint +=
          (obj.Points == null ? 0 : obj.Points) * obj.GoodsCount;
      }
    },
    // 修改购物车数量
    async postEditShopCart(item){
      let params = {};
      params.Id = item.ShopCartId;
      params.GoodsCount = item.GoodsCount;
      const res = await shopCartEdit(params);
      if (res.success == true){
        Toast.success({
          message: res.msg,
        });

        // item.IsReceive = 1;
      } else {
        Toast.fail({
          message: res.msg,
        });
      }
    },
    checkAll(){
      this.$refs.checkboxGroup.toggleAll(this.checked);
      this.total = 0;
      this.totalPoint = 0;
      for (var i = 0; i < this.result.length; i++) {
        let obj = this.goodsList[this.result[i]];
        this.total +=
          (obj.Price == null ? 0 : obj.Price) * obj.GoodsCount * 100;
        this.totalPoint +=
          (obj.Points == null ? 0 : obj.Points) * obj.GoodsCount;
      }
    },
    changeCheckbox(){
      if (this.result.length == this.goodsList.length) {
        this.checked = true;
      }
      if (this.result.length != this.goodsList.length) {
        this.checked = false;
      }
      this.total = 0;
      this.totalPoint = 0;
      for (var i = 0; i < this.result.length; i++) {
        let obj = this.goodsList[this.result[i]];
        this.total +=
          (obj.Price == null ? 0 : obj.Price) * obj.GoodsCount * 100;
        this.totalPoint +=
          (obj.Points == null ? 0 : obj.Points) * obj.GoodsCount;
      }
    },
    delectshop() {
      for (var i = 0; i < this.result.length; i++) {
        let obj = this.goodsList[this.result[i]];
        var data = {};
        data.Id = obj.ShopCartId;
        shopCartIds.push(data);
      }
      this.postShopCartDelete(shopCartIds);
    },
    // 删除
    handleFather(){
      if (this.result.length == 0) {
        Toast.fail({
          message: "请选择要删除的商品",
        });
        return false;
      }
      Dialog.confirm({
        title: "删除商品",
        message: "确定删除购物车中选中的商品吗",
      })
        .then(() =>{
          let shopCartIds = [];
          for (var i = 0; i < this.result.length; i++){
            let obj = this.goodsList[this.result[i]];
            var data = {};
            data.Id = obj.ShopCartId;
            shopCartIds.push(data);
          }
          this.postShopCartDelete(shopCartIds);
        })
        .catch(() => {
          // on cancel
        });
    },
    //删除单个购物车商品
    delitem(itm,idx){
        console.log(itm.ShopCartId,idx);
        Dialog.confirm({
        title: "删除商品",
        message: "确定删除该条商品吗",
       })
        .then(() => {
          let shopCartIds=[];
          var data = {};
          data.Id=itm.ShopCartId;
          shopCartIds.push(data);
          console.log(shopCartIds,88888)
          this.postShopCartDelete(shopCartIds);
          this.checked = false;
          this.getQueryShopCartList()
        })
        .catch(() => {
         //
        });
    },
    // 删除购物车商品
    async postShopCartDelete(parm){
      const res = await shopCartDelete(parm);
      if (res.success == true) {
        Toast.success({
          message: res.msg,
        });
        this.pageIndex = 1;
        this.goodsList = [];
        this.result = [];
        this.total = 0;
        this.totalPoint = 0;
        this.getQueryShopCartList();
        this.getQueryShopCartList2();
      } else {
        Toast.fail({
          message: res.msg,
        });
      }
    },
    //购物车数量
    async getQueryShopCartList2(){
      let parm = {};
      parm.StudentId = this.userInfo.Id;
      const res = await queryShopCartList(parm);
      this.shopcarlength=res.response.dataCount //购物车数量
      this.$store.commit('changeshopcar',this.shopcarlength)
      this.isLoad = false;
      if (res.response.data.length == 0) {
        this.isEnd = true;
      }
    },
    // 列表数据
    async getQueryShopCartList(){
      let parm = {};
      parm.pageIndex = this.pageIndex;
      parm.pageSize = this.pageSize;
      parm.StudentId = this.userInfo.Id;
      const res = await queryShopCartList(parm);
      this.goodsList = res.response.data;
      console.log(this.goodsList, 88888);
      this.isLoad = false;
      this.carnum=this.goodsList.length
      if (res.response.data.length == 0) {
        this.isEnd = true;
      }
    },
    //结算
    onSubmit() {
      let goodsData = [];
      for (var i = 0; i < this.result.length; i++){
        let obj = this.goodsList[this.result[i]];
        // this.total += obj.Price * obj.GoodsCount * 100;
        let data = {};
        data.GoodsId = obj.GoodsId;
        data.SpecId = obj.SpecId;
        data.Count = obj.GoodsCount;
        data.ShopCartId = obj.ShopCartId;
        goodsData.push(data);
      }
      if (goodsData.length == 0) {
        Toast.fail({
          message: "请选择要结算的商品",
        });
        return false;
      }
      this.$router.push({
        path: "/PCshop/goods/check",
        query: { goodsData: JSON.stringify(goodsData) },
      });
    },
    //鼠标移到删除
    moudel(itm,idx){
      this.isdel=idx
    },
     moudel2(){
      this.isdel=999
    },
  },
};
</script>
<style lang='scss' scoped>
.shopcar-content {
  position: relative;
  padding: 30px 0px 0px 0px;
  width: 1100px;
  background: #ffffff;
  margin: 20px auto;
  .shopcar-title {
    font-weight: bold;
    font-size: 18px;
    color: #333333;
    margin-left: 30px;
  }
  .all-title {
    padding-left: 15px;
    width: 1060px;
    background: #f9f9f9;
    height: 50px;
    margin: 21px auto;
    display: flex;
    color: #999999;
    font-size: 14px;
    padding-top: 15px;
  }
}
/deep/ .van-checkbox__icon--round .van-icon {
  border-radius: 0px !important;
}

/deep/ .van-submit-bar__button {
  height: 100%;
  background: #f95e2e !important;
}
/deep/.van-button--round {
  border-radius: 0px;
}
/deep/ .van-submit-bar__bar {
  padding-right: 0px !important;
  height: 100% !important;
}
.van-submit-bar {
  bottom: 31px;
  height: 72px;
  position: absolute !important;
  background: #f9f9f9;
  width: 96%;
  left: 21px;
}
/deep/ .van-submit-bar__suffix-label {
  font-size: 12px;
  color: #ee0a24;
}
.order_info {
  border: 1px solid #efefef;
  background: #fff;
  width: 96%;
  margin: 0 auto;
  // height: 12.6rem;
  box-sizing: border-box;
  padding: 0.9375rem;
  margin-bottom: 0.5rem;
  position: relative;
  &:nth-child(1) {
    margin-top: 0.5rem;
  }
  .info_detail {
    display: flex;
    margin-bottom: 0.9375rem;
    img {
      margin-left: 24px;
      width: 5.5625rem;
      height: 5.5rem;
      border-radius: 0.25rem;
      margin-right: 0.9375rem;
    }
    .detail_content {
      max-width: 307px;
      margin-left: 14px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      div:first-child {
        font-size: 14px;
        color: #333333;
        font-weight: 400;
        width: 308px;
      }
      div:nth-child(2) {
        font-size: 14px;
        color: #999999;
        display: flex;
        justify-content: space-between;
      }
      div:nth-child(3) {
        font-size: 0.75rem;
        color: #fe4a4a;
        span {
          color: #fe4a4a;
          font-size: 0.9375rem;
          font-weight: 700;
        }
      }
    }
    .info_detail-tight {
      margin-top: 18px;
      display: flex;
      .unitprice {
        margin-left: 32px;
        font-size: 12px;
        min-width: 90px;
        text-align: center;
      }
      .info_num2 {
        margin-left: 45px;
      }
      .addprice {
        margin-left: 32px;
        font-size: 12px;
        min-width: 120px;
        text-align: center;
      }
      .del {
        margin-left: 87px;
        font-size: 12px;
      }
    }
  }
  .info_freight {
    display: flex;
    justify-content: flex-end;
    font-size: 0.875rem;
    color: #333333;
    margin-bottom: 1.125rem;
    div:first-child {
      margin-right: 6.18rem;
      span:first-child {
        margin-right: 0.625rem;
      }
      span:nth-child(2) {
        color: #d2d1d1;
      }
    }
    div:nth-child(2) {
      span:first-child {
        font-size: 0.5625rem;
      }
      span:nth-child(2) {
        font-size: 1rem;
        font-weight: 700;
      }
    }
  }
  .info_refund {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 0.75rem;
    div {
      width: 4.125rem;
      height: 1.75rem;
      border-radius: 1.875rem;
      border: 1px solid #e8e8e8;
      font-size: 0.75rem;
      color: #999;
      text-align: center;
      line-height: 1.75rem;
    }
  }
  .info_price {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 1rem;
    color: #333333;
    div:first-child {
      font-weight: 700;
    }
    div:nth-child(2) {
      color: #fe4a4a;
      span:first-child {
        font-size: 0.5625rem;
      }
      span:nth-child(2) {
        font-size: 1rem;
        font-weight: 700;
      }
    }
  }
}
.delselect {
  cursor:pointer;
  margin-left: 20px;
}
/deep/ .van-checkbox-group {
  padding-bottom: 125px !important;
}
</style>